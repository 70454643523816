.hamburger {
  display: none;
  cursor: pointer;
  position: relative;
  z-index: 11;
  pointer-events: all;
  flex-direction: column;
  height: 100%;
}

.hamburger span {
  font: 500 12px/12px Roboto;
}

.hamburger svg {
  height: 3.5rem;
  transition: transform 600ms cubic-bezier(0.4, 0, 0.2, 1);
}

.line {
  fill: none;
  stroke: rgb(0, 0, 0);
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 3;
  transition: stroke-dasharray 600ms cubic-bezier(0.4, 0, 0.2, 1),
                stroke-dashoffset 600ms cubic-bezier(0.4, 0, 0.2, 1);
}

.line-top-bottom {
  stroke-dasharray: 12 63;
}

.hamburger.active > svg {
  transform: rotate(-45deg);
}

.hamburger.active > svg > .line-top-bottom {
  stroke-dasharray: 20 300;
  stroke-dashoffset: -32.42;
}

.checkbox-wrapper:hover .check {
  stroke-dashoffset: 0;
}

.checkbox-wrapper {
  position: relative;
  display: inline-block;
  width: 25px;
  aspect-ratio: 1 / 1;
}

.checkbox-wrapper .background {
  fill: #71dc50;
  transition: ease all 0.6s;
  -webkit-transition: ease all 0.6s;
}

.checkbox-wrapper .stroke {
  fill: none;
  stroke: #fff;
  stroke-miterlimit: 10;
  stroke-width: .2rem;
  stroke-dashoffset: 100;
  stroke-dasharray: 100;
  transition: ease all 0.6s;
  -webkit-transition: ease all 0.6s;
}

.checkbox-wrapper .check {
  fill: none;
  stroke: #fff;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: .4rem;
  stroke-dashoffset: 22;
  stroke-dasharray: 22;
  transition: ease all 0.6s;
  -webkit-transition: ease all 0.6s;
}

.checkbox-wrapper input[type=checkbox] {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  opacity: 0;
}

.checkbox-wrapper input[type=checkbox]:hover {
  cursor: pointer;
}

.checkbox-wrapper input[type=checkbox]:checked + svg .background {
  fill: #71dc50;
}

.checkbox-wrapper input[type=checkbox]:checked + svg .stroke {
  stroke-dashoffset: 0;
}

.checkbox-wrapper input[type=checkbox]:checked + svg .check {
  stroke-dashoffset: 0;
}

@media (max-width: 900px) {
  .hamburger {
    display: flex;
  }
}